import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import A11yDialog from 'a11y-dialog';
import { lenis } from '../lib/lenis';
import { initVideoInfoDialogs } from '../lib/videoDialog';

export async function portfolioPage() {
  initVideoInfoDialogs();

  const dialogEl = document.getElementById('bio-dialog');
  if (dialogEl) {
    const dialog = new A11yDialog(dialogEl);
    dialog.on('show', () => lenis.stop());
    dialog.on('hide', () => lenis.start());
  }

  const biography = document.querySelector('.portfolio__biography');
  const hasBiography = !!biography;

  const tl = gsap.timeline();
  tl.fromTo(
    '.portfolio__heading',
    {
      x: -10,
    },
    {
      x: 0,
      duration: 1.5,
      ease: 'power1.out',
    },
  );
  tl.fromTo(
    '.portfolio__heading',
    {
      clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
    },
    {
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
      duration: 1,
      ease: 'power4.in',
    },
    '<',
  );
  tl.fromTo(
    '.topbar',
    {
      yPercent: -100,
    },
    {
      yPercent: 0,
      y: 0,
      duration: 1,
      ease: 'power2.out',
    },
    '<75%',
  );
  tl.fromTo(
    '.controls',
    { xPercent: -50, yPercent: 50, opacity: 0 },
    {
      xPercent: -50,
      yPercent: 0,
      opacity: 1,
      duration: 0.7,
      ease: 'power4.out',
    },
    '<25%',
  );
  if (hasBiography) {
    tl.fromTo(
      biography,
      { opacity: 0 },
      {
        opacity: 1,
        ease: 'power4.out',
      },
      '<',
    );
  }
  tl.fromTo(
    '.media-slider',
    { opacity: 0 },
    {
      opacity: 1,
      ease: 'power4.out',
    },
    '<',
  );

  const ui = document.querySelector('.media-slider__ui');
  const topWrap = document.querySelector('.portfolio__top-wrap');
  const mediaSlides = [...document.querySelectorAll('.media-slide')];
  const totalSlides = mediaSlides.length;
  const slideIndexElement = document.querySelector(
    '.media-slider__slide-index',
  );

  let mm = gsap.matchMedia();

  mm.add('(max-width: 1023px)', () => {
    mediaSlides.forEach((slide, i) => {
      const isLast = i === totalSlides - 1;

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: slide,
          start: 'top top+=175',
          // markers: true,
          scrub: true,
          onEnter: () => {
            slideIndexElement.textContent = i + 1;
          },
          onEnterBack: () => {
            slideIndexElement.textContent = i + 1;
          },
        },
      });
      if (!isLast) {
        tl.to(slide, {
          ease: 'none',
          opacity: 0,
          scale: 0.8,
        });
      }
    });

    topWrap.style.height = `${ui.getBoundingClientRect().height}px`;
    window.addEventListener('resize', () => {
      topWrap.style.height = `${ui.getBoundingClientRect().height}px`;
    });

    return () => {
      topWrap.style.height = 'auto';
    };
  });

  mm.add('(min-width: 1024px)', () => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.portfolio__top',
          start: 'top top',
          end: `bottom-=91 top`,
          scrub: true,
          ease: 'none',
          invalidateOnRefresh: true,
          // markers: true,
        },
      })
      .to('.portfolio__heading', {
        y: -55,
        fontSize: '26px',
        lineHeight: '35px',
      })
      .to(
        '.portfolio__heading span',
        { fontSize: '25px', lineHeight: '30px' },
        '<',
      )
      .to(
        '.portfolio__biography',
        {
          y: -69,
          fontSize: '13px',
        },
        '<',
      );

    const snapTl = gsap.timeline({
      scrollTrigger: {
        trigger: '.media-slider',
        // markers: true,
        start: 'top top',
        end: 'bottom bottom',
        scrub: 1,
        snap: {
          snapTo: 'labelsDirectional',
          delay: 0,
          ease: 'power4.inOut',
          inertia: false,
        },
      },
    });
    // sets up total timeline length
    snapTl.to(null, { duration: mediaSlides.length - 1 });

    mediaSlides.forEach((slide, i) => {
      const isLast = i === totalSlides - 1;

      // equally divides snapping timeline
      snapTl.addLabel(`slide_${i}`, i);

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: slide,
          start: 'top+=300 top+=91',
          // markers: true,
          scrub: true,
          onEnter: () => {
            slideIndexElement.textContent = i + 1;
          },
          onEnterBack: () => {
            slideIndexElement.textContent = i + 1;
          },
        },
      });

      if (!isLast) {
        tl.to(slide, {
          ease: 'none',
          opacity: 0,
          scale: 0.8,
        });
      }
    });
  });

  gsap.to('.media-slider__pagination', {
    scrollTrigger: {
      trigger: '.media-slider__ui',
      start: `bottom top+=${62 + 30 + 30}`,
      toggleActions: 'play none none reverse',
      // markers: true,
    },
    opacity: 0,
    duration: 0.3,
  });

  // slides inner animations
  mediaSlides.forEach((slide, i) => {
    const bg = slide.querySelector('.media-slide__bg img');
    const header = slide.querySelector('.media-slide__heading');
    const play = slide.querySelector('.media-slide__play');
    const texts = [...slide.querySelectorAll('.media-slide__text')];

    // preload lazy loading images earlier
    ScrollTrigger.create({
      trigger: slide,
      start: 'top top+=250%',
      // markers: true,
      onEnter: () => {
        if (bg) {
          bg.setAttribute('loading', 'eager');
        }
      },
    });

    const animTl = gsap
      .timeline({
        scrollTrigger: {
          trigger: slide,
          start: 'top-=60% top',
          // markers: true,
        },
      })
      .fromTo(
        header,
        {
          x: -10,
        },
        {
          x: 0,
          duration: 1,
          ease: 'power1.out',
        },
      )
      .fromTo(
        header,
        {
          clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
        },
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          duration: 0.8,
          ease: 'power3.out',
        },
        '<',
      );
    animTl.addLabel('texts', '>-=0.8');
    let t = 0;
    texts.forEach((text) => {
      animTl.fromTo(
        text,
        { opacity: 0 },
        { opacity: 1, duration: 0.7, ease: 'power2.in' },
        `texts+=${t}`,
      );
      animTl.fromTo(
        text,
        { yPercent: 50 },
        { yPercent: 0, duration: 0.4, ease: 'power4.out' },
        '<+=0.2',
      );

      t += 0.25;
    });

    animTl.fromTo(
      play,
      { opacity: 0 },
      {
        opacity: 1,
        onComplete: () => {
          setTimeout(() => {
            slide.classList.add('media-slide--has-completed-animation');
          }, 1000);
        },
      },
      '<',
    );
  });
}
