import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { preloadImages } from '../lib/preloadImages';
import { SplitText } from '../lib/SplitText3';
import { lenis } from '../lib/lenis';
import { initVideoInfoDialogs } from '../lib/videoDialog';

async function initLandingPage(lenis) {
  initVideoInfoDialogs();
  const mediaSlides = [...document.querySelectorAll('.media-slide')];
  const totalSlides = mediaSlides.length;

  const logo = document.querySelector('.topbar__logo');

  const tl = gsap.timeline({ defaults: { ease: 'power4.in' } });

  const offer = document.querySelector('.hero-slide__offer');
  tl.set(offer, { opacity: 1 });
  const split = new SplitText(offer, { type: 'lines' });

  tl.fromTo(
    '.hero-slide__video',
    { opacity: 0 },
    { opacity: 1, ease: 'power1.in' },
    '1',
  );

  tl.fromTo(
    '.hero-slide__main-award',
    { opacity: 0 },
    { opacity: 1, ease: 'power1.in' },
    '<',
  );
  tl.fromTo(
    '.hero-slide__main-winning',
    { opacity: 0 },
    { opacity: 1, ease: 'power1.in' },
    '<',
  );
  tl.fromTo(
    '.hero-slide__main-freelance',
    { opacity: 0 },
    { opacity: 1, ease: 'power1.in' },
    '<',
  );
  tl.fromTo(
    '.hero-slide__main-directors',
    { opacity: 0 },
    { opacity: 1, ease: 'power1.in' },
    '<',
  );
  tl.fromTo(
    '.hero-slide__main-dops',
    { opacity: 0 },
    { opacity: 1, ease: 'power1.in' },
    '<',
  );
  tl.fromTo(
    '.hero-slide__main-talent',
    { opacity: 0 },
    { opacity: 1, ease: 'power1.in' },
    '<',
  );
  tl.fromTo(
    '.hero-slide__main-management',
    { opacity: 0 },
    { opacity: 1, ease: 'power1.in' },
    '<',
  );

  tl.fromTo(
    '.topbar',
    {
      yPercent: -100,
    },
    {
      yPercent: 0,
      y: 0,
      duration: 1,
      ease: 'power2.out',
    },
  );

  tl.addLabel('offer', '<');

  let tt = 0;
  split.lines.forEach((line) => {
    tl.fromTo(
      line,
      { opacity: 0 },
      { opacity: 1, duration: 0.7, ease: 'power2.in' },
      `offer+=${tt}`,
    );
    tl.fromTo(
      line,
      { yPercent: 50 },
      { yPercent: 0, duration: 0.4, ease: 'power4.out' },
      '<+=0.2',
    );
    tt += 0.1;
  });

  tl.fromTo(
    '.hero-slide__copyright',
    { opacity: 0 },
    {
      opacity: 1,
      duration: 1,
    },
    '<',
  );
  tl.fromTo(
    '.hero-slide__godown',
    { opacity: 0 },
    {
      opacity: 1,
      duration: 1,
    },
    '<',
  );

  const socials = [
    ...document.querySelectorAll('.hero-slide__social'),
  ].reverse();
  tl.addLabel('socials', '>-=0.3');
  let t = 0;
  socials.forEach((social) => {
    tl.fromTo(
      social,
      { opacity: 0 },
      { opacity: 1, duration: 0.5, ease: 'power2.in' },
      `socials+=${t}`,
    );
    tl.fromTo(
      social,
      { yPercent: 30 },
      { yPercent: 0, duration: 0.3, ease: 'power4.out' },
      '<+=0.2',
    );
    t += 0.1;
  });

  gsap.matchMedia().add('screen and (min-width: 1400px)', () => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.hero-slide',
          start: 'center top',
          end: '+=10',
          toggleActions: 'play none reverse reverse',
          // markers: true,
        },
      })
      .to(logo, { opacity: 0 });

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.media-slider',
          start: 'top top',
          end: '+=10',
          toggleActions: 'play none reverse reverse',
          // markers: true,
        },
      })
      .to('.media-slider__logo', { opacity: 1 });
  });

  const slideIndexElement = document.querySelector(
    '.media-slider__slide-index',
  );

  const snapTl = gsap.timeline({
    scrollTrigger: {
      trigger: '.media-slider',
      // markers: true,
      start: 'top top',
      end: 'bottom bottom',
      scrub: 1,
      snap: {
        snapTo: 'labelsDirectional',
        delay: 0,
        ease: 'power4.inOut',
        inertia: false,
      },
    },
  });
  // sets up total timeline length
  snapTl.to(null, { duration: mediaSlides.length - 1 });

  mediaSlides.forEach((slide, i) => {
    const isLast = i === totalSlides - 1;

    const bg = slide.querySelector('.media-slide__bg img');
    const header = slide.querySelector('.media-slide__heading');
    const play = slide.querySelector('.media-slide__play');
    const texts = [...slide.querySelectorAll('.media-slide__text')];

    // equally divides snapping timeline
    snapTl.addLabel(`slide_${i}`, i);

    // preload lazy loading images earlier
    ScrollTrigger.create({
      trigger: slide,
      start: 'top top+=250%',
      // markers: true,
      onEnter: () => {
        if (bg) {
          bg.setAttribute('loading', 'eager');
        }
      },
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: slide,
        start: 'top+=300 top',
        // markers: true,
        scrub: true,
        onEnter: () => {
          slideIndexElement.textContent = i + 1;
        },
        //onLeave: () => console.log('onLeave'),
        onEnterBack: () => {
          slideIndexElement.textContent = i + 1;
        },
        //onLeaveBack: () => console.log('onLeaveBack'),
      },
    });

    if (!isLast) {
      tl.to(slide, {
        ease: 'none',
        opacity: 0,
        scale: 0.8,
        // scale: 0.6,
        // yPercent: 125
      });
    }

    const animTl = gsap
      .timeline({
        scrollTrigger: {
          trigger: slide,
          start: 'top-=60% top',
          // markers: true,
        },
      })
      .fromTo(
        header,
        {
          x: -10,
        },
        {
          x: 0,
          duration: 1,
          ease: 'power1.out',
        },
      )
      .fromTo(
        header,
        {
          clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
        },
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          duration: 0.8,
          ease: 'power3.out',
        },
        '<',
      );
    animTl.addLabel('texts', '>-=0.8');
    let t = 0;
    texts.forEach((text) => {
      animTl.fromTo(
        text,
        { opacity: 0 },
        { opacity: 1, duration: 0.7, ease: 'power2.in' },
        `texts+=${t}`,
      );
      animTl.fromTo(
        text,
        { yPercent: 50 },
        { yPercent: 0, duration: 0.4, ease: 'power4.out' },
        '<+=0.2',
      );

      t += 0.25;
    });

    animTl.fromTo(
      play,
      { opacity: 0 },
      {
        opacity: 1,
        onComplete: () => {
          setTimeout(() => {
            slide.classList.add('media-slide--has-completed-animation');
          }, 500);
        },
      },
      '<',
    );
  });

  const down = document.querySelector('.hero-slide__godown');
  down.addEventListener('click', () => {
    lenis.scrollTo(mediaSlides[0], { duration: 2 });
  });
}

export async function landingPage(lenis) {
  lenis.scrollTo(0, {
    immediate: true,
    onComplete: () => {
      lenis.stop();
    },
  });

  await preloadImages();
  await initLandingPage(lenis);
  lenis.start();
}
